var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "room-detail lobby-ice-breaker", attrs: { grow: "" } },
    [
      !!(_vm.game && _vm.game.image)
        ? _c("div", {
            staticClass: "room-detail-image",
            style: _vm.cardBodyStyles,
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "room-detail-info h-full rtb-d-flex rtb-flex-col" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", shrink: "", "pb-2": "" } },
            [
              _c("v-flex", { attrs: { grow: "" } }, [
                _c("div", { staticClass: "main-title" }, [
                  _vm._v(_vm._s(_vm.gameName)),
                ]),
              ]),
              _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-0 my-0",
                      attrs: {
                        title: "Arrow Back",
                        icon: "",
                        dark: "",
                        small: "",
                        disabled:
                          _vm.firstMission ||
                          _vm.currentMode === _vm.Mode.Results,
                      },
                      on: { click: _vm.prevMission },
                    },
                    [
                      _c("SvgIcon", {
                        attrs: {
                          name: "arrow-right",
                          width: "20",
                          height: "20",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-0 my-0",
                      attrs: {
                        title: "Arrow right",
                        icon: "",
                        dark: "",
                        small: "",
                        disabled:
                          _vm.lastMission ||
                          _vm.currentMode === _vm.Mode.Results,
                      },
                      on: { click: _vm.nextMission },
                    },
                    [
                      _c("SvgIcon", {
                        attrs: {
                          name: "arrow-left",
                          width: "20",
                          height: "20",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-left": "auto" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "mx-0 my-0",
                                    attrs: {
                                      title: "Close",
                                      icon: "",
                                      dark: "",
                                      small: "",
                                    },
                                    on: { click: _vm.onCloseStandings },
                                  },
                                  on
                                ),
                                [
                                  _c("SvgIcon", {
                                    attrs: {
                                      name: "times-regular",
                                      width: "20",
                                      height: "20",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Close Ice Breaker")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", [_vm._v(_vm._s(_vm.game.description))]),
          _c(
            "div",
            { staticClass: "flex-grow" },
            [
              _vm.currentMission
                ? _c("PlayModeLobby", {
                    attrs: { currentMode: _vm.currentMode },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }