<template>
  <v-flex grow class="room-detail lobby-ice-breaker">
    <div
      v-if="!!(game && game.image)"
      class="room-detail-image"
      :style="cardBodyStyles"
    />
    <div class="room-detail-info h-full rtb-d-flex rtb-flex-col">
      <v-layout row shrink pb-2>
        <v-flex grow>
          <div class="main-title">{{ gameName }}</div>
        </v-flex>

        <v-flex shrink>
          <v-btn
            title="Arrow Back"
            icon
            dark
            small
            class="mx-0 my-0"
            :disabled="firstMission || currentMode === Mode.Results"
            @click="prevMission"
          >
            <SvgIcon name="arrow-right" width="20" height="20" />
          </v-btn>

          <v-btn
            title="Arrow right"
            icon
            dark
            small
            class="mx-0 my-0"
            :disabled="lastMission || currentMode === Mode.Results"
            @click="nextMission"
          >
            <SvgIcon name="arrow-left" width="20" height="20" />
          </v-btn>
        </v-flex>

        <div style="margin-left: auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                title="Close"
                icon
                dark
                small
                class="mx-0 my-0"
                v-on="on"
                @click="onCloseStandings"
              >
                <SvgIcon name="times-regular" width="20" height="20" />
              </v-btn>
            </template>
            <span>Close Ice Breaker</span>
          </v-tooltip>
        </div>
      </v-layout>
      <div>{{ game.description }}</div>

      <div class="flex-grow">
        <PlayModeLobby v-if="currentMission" :currentMode="currentMode" />
      </div>
    </div>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex"
import PlayModeLobby from "@/components/Lobby/Play/PlayModeLobby"
import MissionCollection from "@shared/MissionCollection"
import Mode from "@shared/enums/Mode"
import { db } from "@/firebase"
import Answer from "@shared/Answer"
import useMissions from "@/use/useMissions"

export default {
  name: "GameIceBreakers",
  components: {
    PlayModeLobby
  },
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentMode: Mode.Play,
      Mode
    }
  },
  setup() {
    const { missions } = useMissions()
    return { m: missions }
  },
  beforeDestroy() {
    this.playsRef?.off("value", this.onUserSubmitUpdate)
  },
  async created() {
    await this.$store.dispatch("fetchMissions", this.game.id)
    await this.$store.dispatch("setLocalGame", this.game)
    await this.$store.dispatch("updatePreGameMode", "play")
    await this.$store.dispatch("setLocalCurrentMission", this.missions[0])
  },
  methods: {
    onUserSubmitUpdate(snapshot) {
      this.$store.commit("SET_PLAYS", Answer.normalize(snapshot.val() || {}))
    },
    onCloseStandings() {
      this.$emit("onCloseStandings")
    },
    nextMission() {
      let timeout = 0

      if (this.currentMission.results) {
        timeout = 5000
        this.currentMode = Mode.Results
      }

      setTimeout(() => {
        this.$store.dispatch(
          "setLocalCurrentMission",
          this.missions[this.currentMissionIndex + 1]
        )
        this.currentMode = Mode.Play
      }, timeout)
    },
    prevMission() {
      this.$store.dispatch(
        "setLocalCurrentMission",
        this.missions[this.currentMissionIndex - 1]
      )
    }
  },
  watch: {
    playsRef: {
      handler(newValue, oldValue) {
        oldValue?.off("value", this.onUserSubmitUpdate)
        newValue?.on("value", this.onUserSubmitUpdate)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "isHost"]),
    ...mapGetters(["orgID"]),
    playsRef() {
      return db
        .auxiliary()
        .ref(`org/${this.orgID}/game/${this.game.id}/play`)
        .orderByChild("userID")
        .equalTo(this.user?.id)
        .limitToLast(100)
    },
    cardBodyStyles() {
      return this.game.image
        ? { "background-image": `url("${this.game.image}")` }
        : {}
    },
    missions() {
      return MissionCollection.normalize(this.m)
    },
    gameName() {
      return this.game?.externalName || this.game?.name
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    firstMission() {
      const { missions, currentMission: mission } = this
      return mission && missions[0] && mission.id == missions[0].id
    },
    lastMission() {
      const { missions, currentMission: mission } = this
      return (
        mission &&
        missions[missions.length - 1] &&
        mission.id == missions[missions.length - 1].id
      )
    },
    currentMissionIndex() {
      return parseInt(
        this.missions.findIndex(
          mission => mission.id === this.currentMission.id
        ) || 0
      )
    }
  }
}
</script>

<style lang="scss">
.lobby-ice-breaker {
  position: absolute;
  height: 430px;
  left: 50px;
  right: 50px;
  bottom: 0;
  z-index: 110;
  background-color: #586475 !important;

  .main-title {
    padding: 0;
    line-height: 1;
  }
}

.flash-game-info {
  line-height: 1;
  .ice-icon {
    width: 33px;
    height: auto;
  }
  .flash-card-nav-bar {
    user-select: none;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin-right: 150px;

    .arrow-text {
      font-size: 20px;
      cursor: pointer;
    }
    .arrow-separator {
      font-size: 17px;
      height: 31px;
    }
  }
  .trivia-text {
    color: $primary_accent_color;
    font-size: 17px;
    font-weight: 800;
  }
  .bottom-trivia-text {
    font-weight: bold;
  }
  .trivia-text {
    height: 28px;
    line-height: 24px;
    text-shadow: 1px 1px 1px rgba($color-black, 0.5);
  }
  .ice-breakers-text {
    font-size: 24px;
    line-height: 27px;
    color: $color-white;
    font-weight: 800;
    white-space: nowrap;
    text-shadow: 1px 1px 1px rgba($color-black, 0.25);
  }
  .ice-breakers-separator {
    color: $color-white;
    line-height: 27px;
  }
  .nav-icon {
    font-weight: bold;
    font-size: 40px !important;
  }
  .arrow {
    position: relative;
    width: 23px;
    height: 23px;
    color: orangered;
    cursor: pointer;
    // &:before,
    // &:after {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: contain;
    //   transition: all 0.1s ease-out;
    // }
    // &:before {
    //   background-image: url("../assets/arrow-white.svg");
    // }
    // &:after {
    //   background-image: url("../assets/arrow-orange.svg");
    //   opacity: 0;
    // }
  }
  // .next .arrow {
  //   &:before,
  //   &:after {
  //     right: 0;
  //   }
  // }
  // .prev .arrow {
  //   &:before,
  //   &:after {
  //     left: 0;
  //     // transform: scaleX(-1);
  //   }
  // }
  .next,
  .prev {
    transition: color 0.1s ease-out;
    cursor: pointer;
    &:hover {
      color: $primary_accent_color;
      .arrow {
        color: $primary_accent_color !important;
      }
    }
  }
}
</style>
